/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import SearchIcon from "@material-ui/icons/Search"
import { Link } from "react-router-dom"
import SaveIcon from "@material-ui/icons/Save"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import { Paper } from "@material-ui/core"
import Downshift from "downshift"
import SetDateRangeDialog from "../../Customers/Locations/Meals/SetDateRangeDialog"
import {
    renderInput,
    getSuggestions,
    renderSuggestion
} from "../../Common/downshiftFunctions"
import { useStyles } from "../../Common/_styles/filterPanelStyles"
import { formatDate } from "../../Common/momentFunctions"
import DishLoader from "../../Common/DishLoader"
import ExportButton from "../../Reports/ExportButton"
import fi from "date-fns/locale/fi"


function FilterPanel({
    showCustomerField,
    onSearchParamsSave,
    initialSearchParams,
    customerData,
    itemsData,
    downloadEmployeesOrdersCSV,
    isLoadingFile,
    exportData,
    fileName,
    intl
}) {
    const classes = useStyles()

    const totals = { breakfastTotal: 0, lunchTotal: 0, dinnerTotal: 0, breakfastRefundTotal: 0, lunchRefundTotal: 0, dinnerRefundTotal: 0 }

    itemsData.forEach(el => {
        totals.breakfastTotal += el.Breakfast
        totals.lunchTotal += el.Luanch
        totals.dinnerTotal += el.Dinner
        totals.breakfastRefundTotal += el.BreakfastRefund
        totals.lunchRefundTotal += el.LuanchRefund
        totals.dinnerRefundTotal += el.DinnerRefund
    })

    const [searchParams, setSearchParams] = useState(initialSearchParams)
    const [dateRangeDialogState, setDateRangeDialogState] = useState({
        show: false
    })

    const handleSearchParamChange = name => data => {
        let value
        if (data.target) {
            value = data.target.value
        } else {
            value = data
        }
        setSearchParams(oldsearchParams => ({
            ...oldsearchParams,
            [name]: value
        }))
    }

    function handleSetDateRange(from, to) {
        handleSearchParamChange("from")(from)
        handleSearchParamChange("to")(to)
        setDateRangeDialogState({ show: false })
    }

    function handleOpenSetDateRangeDialog() {
        setDateRangeDialogState({
            show: true,
            from: searchParams.from,
            to: searchParams.to
        })
    }

    function handleSearchParamsSubmit(event) {
        event.preventDefault()
        onSearchParamsSave(searchParams)
    }


    return (
        <>
            <SetDateRangeDialog
                intl={intl}
                show={dateRangeDialogState.show}
                saveRange={(from, to) => handleSetDateRange(from, to)}
                closeDialog={() => setDateRangeDialogState({ show: false })}
                maxDate={dateRangeDialogState.maxDate}
                minDate={dateRangeDialogState.minDate}
                maxRange={35}
                initialStartDate={dateRangeDialogState.from}
                initialEndDate={dateRangeDialogState.to}
            />
            <form
                className={classes.root}
                onSubmit={handleSearchParamsSubmit}
                autoComplete="off"
            >
                {showCustomerField && (
                    <Downshift
                        id="downshift-for-customer"
                        selectedItem={
                            searchParams.customer.Name ? searchParams.customer : null
                        }
                        onChange={selected => {
                            handleSearchParamChange("location")("")
                            handleSearchParamChange("customer")(selected || {})
                        }}
                        itemToString={item => (item ? item.Name : "")}
                    >
                        {({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            inputValue,
                            isOpen,
                            openMenu,
                            selectedItem
                        }) => {
                            const {
                                onChange,
                                onBlur,
                                onFocus,
                                ...inputProps
                            } = getInputProps({
                                onChange: event => {
                                    if (event.target.value === "") {
                                        clearSelection()
                                    }
                                },
                                onFocus: openMenu,
                                placeholder: intl.formatMessage({
                                    id: "FILTER.CUSTOMER_FIELD.PLACEHOLDER.ALL"
                                })
                            })

                            return (
                                <div className={classes.container}>
                                    {renderInput({
                                        fullWidth: true,
                                        classes,
                                        label: intl.formatMessage({
                                            id: "FILTER.CUSTOMER_FIELD.LABEL"
                                        }),
                                        nested: true,
                                        InputLabelProps: getLabelProps({
                                            shrink: true
                                        }),
                                        InputProps: { onBlur, onChange, onFocus },
                                        inputProps
                                    })}

                                    <div {...getMenuProps()}>
                                        {isOpen ? (
                                            <Paper className={classes.paper} square>
                                                {getSuggestions(inputValue, customerData.customers, {
                                                    showEmpty: true
                                                }).map((suggestion, index) =>
                                                    renderSuggestion({
                                                        suggestion,
                                                        index,
                                                        itemProps: getItemProps({
                                                            item: suggestion
                                                        }),
                                                        highlightedIndex,
                                                        selectedItem
                                                    })
                                                )}
                                            </Paper>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        }}
                    </Downshift>
                )}
                <FormControl
                    className={classes.formControl}
                    disabled={!searchParams.customer.Name}
                >
                    <InputLabel htmlFor="location">
                        <FormattedMessage id="FILTER.LOCATION_FIELD.LABEL" />
                    </InputLabel>
                    <Select
                        value={searchParams.location}
                        onChange={handleSearchParamChange("location")}
                        inputProps={{
                            name: "location",
                            id: "location"
                        }}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="FILTER.COMMON.ALL" />
                        </MenuItem>
                        {searchParams.customer.Locations &&
                            searchParams.customer.Locations.map(location => (
                                <MenuItem key={location.LocationID} value={location.LocationID}>
                                    {location.Name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl
                    className={classes.formControl}
                    disabled={!searchParams.customer.Name}
                >
                    <InputLabel htmlFor="department">
                        <FormattedMessage id="FILTER.DEPARTMENT_FIELD.LABEL" />
                    </InputLabel>
                    <Select
                        value={searchParams.department}
                        onChange={handleSearchParamChange("department")}
                        inputProps={{
                            name: "department",
                            id: "department"
                        }}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="FILTER.COMMON.ALL" />
                        </MenuItem>
                        {searchParams.customer.Departments &&
                            searchParams.customer.Departments.map(department => (
                                <MenuItem
                                    key={department.DepartmentID}
                                    value={department.DepartmentID}
                                >
                                    {department.Name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <div className="d-flex flex-row justify-content-center align-items-baseline">
                    <TextField
                        className={classes.textField}
                        inputProps={{
                            readOnly: true
                        }}
                        margin="normal"
                        label={intl.formatMessage({
                            id: "DATE_FIELD.FROM"
                        })}
                        fullWidth
                        value={searchParams.from ? formatDate(searchParams.from) : ""}
                        onClick={handleOpenSetDateRangeDialog}
                    />
                    <div className={classes.divider}>-</div>
                    <TextField
                        className={classes.textField}
                        inputProps={{
                            readOnly: true
                        }}
                        margin="normal"
                        label={intl.formatMessage({
                            id: "DATE_FIELD.TO"
                        })}
                        fullWidth
                        value={searchParams.to ? formatDate(searchParams.to) : ""}
                        onClick={handleOpenSetDateRangeDialog}
                    />
                </div>
                <Button
                    id="search_params_submit"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    dir="ltr"
                    disabled={
                        !searchParams.from ||
                        !searchParams.to ||
                        !searchParams.customer.CustomerID
                    }
                    className={`font-weight-bold my-3 ml-10`}
                >


                    <span>
                        <FormattedMessage id="FILTER.SEARCH_BUTTON" /> <SearchIcon />
                    </span>
                </Button>

                <Button
                    variant="contained"
                    type="button"
                    color="secondary"
                    size="large"
                    dir="ltr"
                    target="_blank"
                    disabled={
                        isLoadingFile ||
                        !searchParams.from ||
                        !searchParams.to ||
                        !searchParams.customer.CustomerID
                    }
                    className={`font-weight-bold my-3 ml-10`}

                    onClick={downloadEmployeesOrdersCSV}
                >
                    {isLoadingFile ? (
                        <DishLoader minHeight={30} height={30} />
                    ) : (
                        <span>
                            <FormattedMessage id="FILTER.DOWNLOADFILE" /> <SaveIcon />
                        </span>
                    )}
                </Button>

                {itemsData.length !== 0 && (
                    <div className="text-right ml-10">
                        <ExportButton
                            exportData={exportData}
                            fileName={fileName}
                        />
                    </div>
                )}
            </form>
            {itemsData.length !== 0 && (
                <div className={`font-weight-bold mb-1 d-flex justify-content-around`}>
                    <div>
                        <FormattedMessage id="FILTER.EMPLOYEES_TOTAL" /> {itemsData.length}
                    </div>
                    <div>
                        <FormattedMessage id="FILTER.BREAKFAST_TOTAL" />{" "}
                        {totals.breakfastTotal}{totals.breakfastRefundTotal !== 0 ? `(${totals.breakfastRefundTotal}) ` : ''}
                    </div>
                    <div>
                        <FormattedMessage id="FILTER.LUNCH_TOTAL" />
                        {totals.lunchTotal}{totals.lunchRefundTotal !== 0 ? `(${totals.lunchRefundTotal}) ` : ''}
                    </div>
                    <div>
                        <FormattedMessage id="FILTER.DINNER_TOTAL" />
                        {totals.dinnerTotal}{totals.dinnerRefundTotal !== 0 ? `(${totals.dinnerRefundTotal}) ` : ''}
                    </div>
                </div>
            )}
        </>
    )
}

export default injectIntl(FilterPanel)
