/* eslint-disable no-restricted-imports */

import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
import EnhancedTableHead from "../../Common/EnhancedTableHead";
import MealItem from "./MealItem";
import { useStyles } from "../../Common/_styles/elementListStyles";
import { stableSort } from "../../Common/tableSortingFunctions";
import { Checkbox, TablePagination } from "@material-ui/core";

const _ = require('lodash');

const headRows = [
    {
        id: "Settings",
        disablePadding: false,
        notSortable: true,
        label: ""
    },
    {
        id: "Image",
        disablePadding: false,
        notSortable: true,
        label: ""
    },
    {
        id: "Name",
        disablePadding: false,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.NAME" />
    },
    {
        id: "SupplierName",
        disablePadding: false,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.SUPPLIER_NAME" />
    },
    {
        id: "Priority",
        disablePadding: false,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.PRIORITY" />
    },
    {
        id: "Options",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.OPTIONS" />
    },
    {
        id: "ItemPrice",
        disablePadding: false,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.COST_PRICE" />
    },
    {
        id: "ClientPrice",
        disablePadding: false,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.EMPLOYEE_PRICE" />
    },
    {
        id: "CustomerPrice",
        disablePadding: false,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.CUSTOMER_PRICE" />
    },
    {
        id: "Days",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.DAYS_AVAILABILITY" />
    },
    {
        id: "Dates",
        disablePadding: false,
        notSortable: true,
        label: <FormattedMessage id="MEAL_ITEM.LABELS.DATE_AVAILABILITY" />
    }
];

export function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

function desc(a, b, orderBy) {
    if (orderBy === "Name") {
        return b.Item.Name < a.Item.Name ? -1 : 1;
    }
    if (orderBy === "ItemPrice") {
        return b.Item.Price < a.Item.Price ? -1 : 1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function MealItemList({
    mealItems,
    onSelect,
    onItemFieldChange,
    loading,
    selected,
    onToggleAll,
    intl
}) {
    const classes = useStyles();
    const rows = mealItems || [];

    const [sortBy, setSortBy] = useState("FullName");
    const [orderBy, setOrderBy] = useState("asc");
    const [paginationState, setPaginationState] = useState({
        rowsPerPage: 10,
        page: 0
    });

    useEffect(() => {
        setPaginationState({
            ...paginationState,
            page: 0
        });
    }, [paginationState.rowsPerPage]);

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc";
        setOrderBy(isDesc ? "asc" : "desc");
        setSortBy(property);
    }

    function handlePaginationStateChange(changes) {
        const newState = { ...paginationState, ...changes };
        setPaginationState(newState);
    }

    function handleChangePage(event, newPage) {
        handlePaginationStateChange({ page: newPage });
    }

    function handleChangeRowsPerPage(event) {
        const rowsPerPageValue = +event.target.value;
        handlePaginationStateChange({ rowsPerPage: rowsPerPageValue });
    }

    return (
        <div>
            {!loading && rows.length && (
                <>
                    <div>
                        <span className={classes.totalListCount}>
                            {intl.formatMessage({
                                id: "MEAL_ITEMS.LABELS.COUNT"
                            })}
                        </span>
                        <span>{rows.length}</span>
                    </div>
                    <div>
                        <span className={classes.totalListCount}>
                            {intl.formatMessage({
                                id: "MEAL_ITEMS.LABELS.SELECTED_COUNT"
                            })}
                        </span>
                        <span>{selected.length}</span>
                    </div>
                </>
            )}
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table aria-labelledby="tableTitle" size="small">
                        <EnhancedTableHead
                            headRows={[
                                {
                                    ...headRows[0],
                                    label: (
                                        <Checkbox
                                            indeterminate={
                                                selected.length > 0 && selected.length < rows.length
                                            }
                                            style={{ padding: 0 }}
                                            checked={selected.length === rows.length}
                                            onChange={event => {
                                                onToggleAll(event.target.checked);
                                            }}
                                        />
                                    )
                                },
                                ...headRows.slice(1)
                            ]}
                            orderBy={orderBy}
                            sortBy={sortBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(orderBy, sortBy))
                                /*.slice(
                                                paginationState.page * paginationState.rowsPerPage,
                                                (paginationState.page + 1) * paginationState.rowsPerPage
                                            )*/
                                .map((row, index) => {
                                    return (
                                        <MealItem
                                            key={(row.IsCombo ? row.Combo.Id : row.Item.ItemID) + row.id}
                                            row={row}
                                            selected={selected}
                                            onSelect={ev => onSelect(ev, index)}
                                            onItemFieldChange={(value, oldValue) => {
                                                if (!_.isEqual(value, oldValue)) {
                                                    onItemFieldChange(value);
                                                }
                                            }}
                                        />
                                    );
                                })}
                        </TableBody>
                    </Table>
                </div>
                {/*<div>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 30]}
                    component="div"
                    count={mealItems.data.length}
                    rowsPerPage={paginationState.rowsPerPage}
                    page={paginationState.page}
                    labelRowsPerPage={intl.formatMessage({
                        id: "TABLE.PAGINATION.ROWS_PER_PAGE"
                    })}
                    backIconButtonProps={{
                        "aria-label": intl.formatMessage({
                            id: "TABLE.PAGINATION.PREVIOUS_PAGE"
                        })
                    }}
                    nextIconButtonProps={{
                        "aria-label": intl.formatMessage({
                            id: "TABLE.PAGINATION.NEXT_PAGE"
                        })
                    }}
                    labelDisplayedRows={({ from, to, count }) =>
                        intl.formatMessage(
                            {
                                id: "TABLE.PAGINATION.DISPLAYED_ROWS_LABEL"
                            },
                            {
                                from,
                                to,
                                count
                            }
                        )
                    }
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>*/}
            </Paper>
        </div>
    );
}

export default React.memo(MealItemList);
