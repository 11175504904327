/* eslint-disable no-restricted-imports */

import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { FormattedMessage, injectIntl } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { stableSort, getSorting } from "../../Common/tableSortingFunctions"
import { Link } from "react-router-dom"
import { formatDateForApi } from "../../Common/momentFunctions"
import DishLoader from "../../Common/DishLoader"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
    {
        id: "FullName",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.FULL_NAME" />
    },
    {
        id: "ExternalId",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.EXTERNAL_ID" />
    },
    {
        id: "DepartmentName",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.DEPARTMENT_NAME" />
    },
    {
        id: "Breakfast",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.BREAKFAST" />
    },
    {
        id: "Luanch",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.LUNCH" />
    },
    {
        id: "Dinner",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.DINNER" />
    }
]

function ReportsList({ reportsData, fromDate, toDate, intl }) {
    const classes = useStyles()
    const rows = reportsData.reports
    const [sortBy, setSortBy] = useState("FullName")
    const [orderBy, setOrderBy] = useState("asc")

    function handleRequestSort(event, property) {
        const isDesc = sortBy === property && orderBy === "desc"
        setOrderBy(isDesc ? "asc" : "desc")
        setSortBy(property)
    }

    return (
        <Paper className={classes.paper}>
            {reportsData.isLoading && <DishLoader centered />}
            <div className={classes.tableWrapper}>
                <Table aria-labelledby="tableTitle" size="small">
                    <EnhancedTableHead
                        headRows={headRows}
                        orderBy={orderBy}
                        sortBy={sortBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {stableSort(rows, getSorting(orderBy, sortBy)).map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.UserID}>
                                    <TableCell component="th" scope="row" align="left">
                                        <Link
                                            to={`/user/${row.UserID
                                                }/order-history?fromDate=${formatDateForApi(
                                                    fromDate
                                                )}&toDate=${formatDateForApi(toDate)}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {row.FullName} {(row.CreatorName &&
                                                row.CreatorName !== row.FullName) ? '(' + row.CreatorName + ')' : ''}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.ExternalID}</TableCell>
                                    <TableCell>{row.DepartmentName}</TableCell>
                                    <TableCell>{row.BreakfastRefund !== 0 ? `(${row.BreakfastRefund}) ` : ''}{row.Breakfast}</TableCell>
                                    <TableCell>{row.LuanchRefund !== 0 ? `(${row.LuanchRefund}) ` : ''}{row.Luanch}</TableCell>
                                    <TableCell>{row.DinnerRefund !== 0 ? `(${row.DinnerRefund}) ` : ''}{row.Dinner}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    )
}

export default injectIntl(ReportsList)
