/* eslint-disable no-restricted-imports */

import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import EnhancedTableHead from "../../Common/EnhancedTableHead"
import { useStyles } from "../../Common/_styles/elementListStyles"

const headRows = [
    {
        id: "Name",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    },
    {
        id: "ExternalId",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    },
    {
        id: "Mobile",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    },
    {
        id: "Email",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    },
    {
        id: "Department",
        disablePadding: false,
        notSortable: true,
        width: 15,
        label: ""
    },
    {
        id: "MealType1",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.BREAKFAST" />
    },
    {
        id: "MealType2",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.LUNCH" />
    },
    {
        id: "MealType3",
        disablePadding: false,
        label: <FormattedMessage id="REPORT.DINNER" />
    }
]

function OrderItemsUsersSummary({ reportsData }) {
  const classes = useStyles()
  const rows = reportsData
  const [sortBy, setSortBy] = useState("Name")
  const [orderBy, setOrderBy] = useState("asc")

  function handleRequestSort(event, property) {
    const isDesc = sortBy === property && orderBy === "desc"
    setOrderBy(isDesc ? "asc" : "desc")
    setSortBy(property)
  }

  const totalMealType1 = reportsData.reduce((sum, report) => sum + report.MealType1, 0)
  const totalMealType2 = reportsData.reduce((sum, report) => sum + report.MealType2, 0)
  const totalMealType3 = reportsData.reduce((sum, report) => sum + report.MealType3, 0)

  const totalMealTypeRefund1 = reportsData.reduce((sum, report) => sum + report.MealTypeRefund1, 0)
  const totalMealTypeRefund2 = reportsData.reduce((sum, report) => sum + report.MealTypeRefund2, 0)
  const totalMealTypeRefund3 = reportsData.reduce((sum, report) => sum + report.MealTypeRefund3, 0)

  return (
    <Paper className={classes.paper}>
      <div className={classes.tableWrapperSummary}>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            headRows={headRows}
            orderBy={orderBy}
            sortBy={sortBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={classes.collapsibleRow}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{totalMealTypeRefund1 !== 0 ? `(${totalMealTypeRefund1}) ` : ''}{totalMealType1}</TableCell>
              <TableCell>{totalMealTypeRefund2 !== 0 ? `(${totalMealTypeRefund2}) ` : ''}{totalMealType2}</TableCell>
              <TableCell>{totalMealTypeRefund3 !== 0 ? `(${totalMealTypeRefund3}) ` : ''}{totalMealType3}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Paper>
  )
}

export default OrderItemsUsersSummary
